import s from './index.module.scss';

import * as React from 'react';
import AgeGate from '../pages/agegate/index';

const App = () => {
  return (
    <main id={s.app}>
      <AgeGate />
    </main>
  );
};

export default App;
